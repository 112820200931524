import React from "react";
import ThemeProvider, { ThemeNames } from "@ryerson/frontend.theme";
import { MediaContextProvider } from "@ryerson/frontend.layout";
import { ErrorBoundary } from "@ryerson/frontend.errors";

export const Boot = ({ element }: any) => {
	const themeName: ThemeNames = ThemeNames[process.env.GATSBY_THEME as keyof typeof ThemeNames];
	const company = process.env.GATSBY_COMPANY as "ryerson" | "southernToolSteel" | undefined;

	return (
		<ErrorBoundary company={company}>
			
					<ThemeProvider themeName={themeName}>
							<MediaContextProvider>{element}</MediaContextProvider>
					</ThemeProvider>
			</ErrorBoundary>
	);
};
