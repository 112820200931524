import React from "react";
import styled from "@emotion/styled";

const ContainerDiv = styled.div`
	display: block;
	width: 1280px;
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
	box-sizing: border-box;
	max-width: 100%;
	@media (max-width: 992px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

type ContainerProps = {
	children: any;
	className?: string;
};

const Container: React.FC<ContainerProps> = ({ children, className }) => {
	return <ContainerDiv className={className}>{children}</ContainerDiv>;
};

export default Container;
