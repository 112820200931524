import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { Container, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import ButtonLink from "@components/ButtonLink";
import { HeaderContent, HeaderContentType } from "@components/Header";
import { useTheme } from "@ryerson/frontend.theme";

const NextFooter = () => {
	const { theme } = useTheme();

	return (
		<Container>
			<Flex
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				css={css`
					@media (max-width: ${theme.breakpoints.lg}) {
						flex-direction: column-reverse;
						gap: 50px;
					}
				`}
			>
				<FlexItem
					css={css`
						text-align: center;
					`}
				>
					<Link to="/">
						<img
							src={"/images/the-next-shift.png"}
							css={css`
								height: 57px;
								width: auto;
								vertical-align: top;
								display: inline-block;
								@media (max-width: ${theme.breakpoints.lg}) {
									height: 37px;
								}
							`}
						/>
					</Link>
					<img
						src={"/images/powered-by-ryerson.png"}
						css={css`
							height: 57px;
							width: auto;
							margin-left: 40px;
							display: inline-block;
							vertical-align: top;
							@media (max-width: ${theme.breakpoints.lg}) {
								height: 37px;
								margin-left: 20px;
							}
						`}
					/>
					<Flex
						direction="row"
						alignItems="center"
						css={css`
							width: 100%;
							padding-top: 15px;
							gap: 20px;
							text-align: center;
						`}
					>
						<FlexItem>
							<Link
								to="/documents/Privacy-Policy-Ryerson.pdf"
								gatsby={false}
								target="_blank"
							>
								<Typography
									variant="div"
									size="xs"
									color={theme.colors.primary.gray}
								>
									Privacy Policy
								</Typography>
							</Link>
						</FlexItem>
						<FlexItem>
							<Link to="/documents/CCPA.pdf" gatsby={false} target="_blank">
								<Typography
									variant="div"
									size="xs"
									color={theme.colors.primary.gray}
								>
									CCPA
								</Typography>
							</Link>
						</FlexItem>
						<FlexItem>
							<Typography variant="div" size="xs" type="primary">
								©2023 Ryerson Holding Corporation. All Rights Reserved.
							</Typography>
						</FlexItem>
					</Flex>
				</FlexItem>
				<FlexItem>
					<Typography
						size="sm"
						weight="bold"
						variant="div"
						css={css`
							margin-bottom: 20px;
							text-transform: uppercase;
						`}
						color={theme.colors.primary.primaryBrand}
					>
						Site Links
					</Typography>
					<Flex
						direction="row"
						css={css`
							gap: 30px;
							@media (max-width: ${theme.breakpoints.lg}) {
								flex-direction: column;
								gap: 20px;
							}
						`}
						alignItems="center"
						justifyContent="space-between"
					>
						{HeaderContent.map((item: HeaderContentType, index: number) => {
							return (
								<FlexItem key={index}>
									<Link to={item.url}>
										<Typography color={theme.colors.primary.secondaryBrand}>
											{item.label}
										</Typography>
									</Link>
								</FlexItem>
							);
						})}
					</Flex>
				</FlexItem>
				<FlexItem>
					<Flex
						direction="column"
						alignItems="center"
						css={css`
							gap: 10px;
							padding-right: 60px;
							@media (max-width: ${theme.breakpoints.lg}) {
								padding-right: 0px;
							}
						`}
					>
						<FlexItem>
							<Typography
								size="sm"
								weight="bold"
								css={css`
									text-transform: uppercase;
								`}
								color={theme.colors.primary.primaryBrand}
							>
								Join the Community
							</Typography>
						</FlexItem>
						<FlexItem>
							<ButtonLink
								external={true}
								to="https://mktg.ryerson.com/l/513681/2023-06-21/gfp17k"
							>
								Start Here
							</ButtonLink>
						</FlexItem>
					</Flex>
				</FlexItem>
			</Flex>
		</Container>
	);
};

export default NextFooter;
