import styled from "@emotion/styled";
import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useTheme } from "@ryerson/frontend.theme";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import { Flex } from "@ryerson/frontend.layout";

export type VideoType = "YouTube";

enum VideoUrls {
	YouTube = "https://www.youtube.com/embed/",
}

export interface VideoProps {
	className?: string;
	videoType?: VideoType;
	videoId: string;
	controls?: boolean;
	hidePlayButton?: boolean;
	imageUrl?: string;
	text?: string;
}

const VideoWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const VideoContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const PlayButton = styled.div`
	border-radius: 250px;
	align-self: center;

	${(props: any) => {
		const { theme } = props;

		return css`
			border: 6px solid ${theme.colors.primary.white};
			position: absolute;
			height: 60px;
			width: 60px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
		`;
	}}

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 32px;
		height: 32px;
	}
`;

export const Video: React.FC<VideoProps> = ({
	className,
	videoType = "YouTube",
	videoId,
	controls = true,
	hidePlayButton = false,
	imageUrl = "",
}) => {
	const { theme } = useTheme();
	const { white } = theme.colors.primary;

	const url = VideoUrls[videoType] + videoId;

	const [playing, setPlaying] = useState<boolean>(false);
	const [lightMode, setLightMode] = useState<boolean>(hidePlayButton ? false : true);
	const playVideo = () => {
		setPlaying(!playing);
		setLightMode(false);
	};

	return (
		<VideoContainer>
			<VideoWrapper className={className}>
				{!imageUrl || playing ? (
					<ReactPlayer
						url={url}
						playing={playing}
						controls={controls}
						width="100%"
						height="100%"
						light={lightMode}
						playIcon={
							hidePlayButton ? (
								<></>
							) : (
								<PlayButton theme={theme} onClick={playVideo}>
									<Icon
										icon="play"
										color={theme.colors.primary.white}
										onClick={playVideo}
										size="fit"
									/>
								</PlayButton>
							)
						}
					/>
				) : (
					<Flex
						justifyContent="center"
						style={css`
							width: 100%;
							height: 100%;
						`}
					>
						<img width="100%" height="100%" src={imageUrl}></img>
						{hidePlayButton ? (
							<></>
						) : (
							<PlayButton theme={theme} onClick={playVideo}>
								<Icon icon="play" color={white} onClick={playVideo} />
							</PlayButton>
						)}
					</Flex>
				)}
			</VideoWrapper>
		</VideoContainer>
	);
};
