import { useStaticQuery, graphql } from "gatsby";
import { BlogNodeBodyReferenceType } from "./blogMetaContent";

export type ImageType = {
	file: {
		url: string;
	};
};

export type RichContentType = {
	raw: string;
	references?: BlogNodeBodyReferenceType[];
};

export type MainContentNodesType = {
	aboutUsRichContent: RichContentType;
	aboutUsVideoLink: string;
	aboutUsVideoThumbnailOrImage: ImageType;
	expertiseDottedText: RichContentType;
	expertiseHeroImage: ImageType;
	expertiseHeroSubtitle: string;
	expertiseHeroTitle: string;
	expertiseLeftTextLink: string;
	expertiseLeftTextParagraph: { expertiseLeftTextParagraph: string };
	expertiseLeftTextTitle: string;
	expertiseRightTextLink: string;
	expertiseRightTextParagraph: { expertiseRightTextParagraph: string };
	expertiseRightTextTitle: string;
	expertiseRightVideoLink: string;
	expertiseRightVideoThumbnail: ImageType;
	expertiseThinglink: string;
	homepageRightVideoThumbnail: ImageType;
	homepageRightVideoId: string;
	homepageRightVideoTitle: string;
	homepageLeftVideoTitle: string;
	homepageLeftVideoThumbnail: ImageType;
	homepageLeftVideoId: string;
};

export type MainContentType = {
	allContentfulTheNextShiftMainContent: { nodes: MainContentNodesType[] };
};

export const MainContent = () => {
	let content: MainContentType = mainQuery();
	return content.allContentfulTheNextShiftMainContent.nodes[0];
};

export const mainQuery = () => {
	return useStaticQuery(graphql`
		query mainContent {
			allContentfulTheNextShiftMainContent(
				filter: { node_locale: { eq: "en-US" }, version: { eq: "Production" } }
			) {
				nodes {
					aboutUsRichContent {
						raw
						references {
							... on ContentfulAsset {
								id
								contentful_id
								file {
									url
								}
							}
							... on ContentfulTheNextShiftEmbeddedCallout {
								id
								contentful_id
								callout {
									callout
								}
							}
							... on ContentfulTheNextShiftEmbeddedQuote {
								id
								author
								quote {
									quote
								}
								contentful_id
							}
						}
					}
					aboutUsVideoLink
					aboutUsVideoThumbnailOrImage {
						file {
							url
						}
						contentful_id
					}
					homepageRightVideoThumbnail {
						file {
							url
						}
					}
					homepageRightVideoId
					homepageLeftVideoThumbnail {
						file {
							url
						}
					}
					homepageLeftVideoTitle
					homepageRightVideoTitle
					homepageLeftVideoId
					expertiseThinglink
					expertiseRightVideoThumbnail {
						file {
							url
						}
					}
					expertiseRightVideoLink
					expertiseRightTextTitle
					expertiseRightTextParagraph {
						expertiseRightTextParagraph
					}
					expertiseRightTextLink
					expertiseLeftTextTitle
					expertiseLeftTextParagraph {
						expertiseLeftTextParagraph
					}
					expertiseLeftTextLink
					expertiseHeroTitle
					expertiseHeroSubtitle
					expertiseHeroImage {
						file {
							url
						}
					}
					expertiseDottedText {
						raw
					}
				}
			}
		}
	`);
};
