import { useStaticQuery, graphql } from "gatsby";

export type ImageType = {
	file: {
		url: string;
	};
};

export type BlogMetaNodeType = {
	node_locale: string;
	url: string;
	title: string;
	heroImage: ImageType;
	date: string;
	category: string;
};

export type BlogNodeBodyReferenceType = {
	id: string;
	contentful_id: string;
	callout?: {
		callout: string;
	};
	file?: {
		url: string;
	};
	author?: string;
	quote?: {
		quote: string;
	};
	videoId?: string;
	thumbnail?: ImageType;
	label?: string;
	downloadableFile?: {
		file: {
			fileName: string;
			url: string;
		};
	};
	linkButtonUrl?: string;
};

export type BlogNodeType = {
	body: {
		raw: string;
		references?: BlogNodeBodyReferenceType[];
	};
	ctaLink: string;
	ctaText: string;
	category: string;
	date: string;
	heroImage: ImageType;
	node_locale: string;
	seoDescription: string;
	title: string;
	url: string;
};

export type BlogMetaType = {
	allContentfulTheNextShiftInsight: {
		nodes: BlogMetaNodeType[];
	};
};

export const BlogMetaContent = () => {
	let content: BlogMetaType = metaQuery();
	return content.allContentfulTheNextShiftInsight.nodes
		.filter((node: BlogMetaNodeType) => node.node_locale === "en-US")
		.sort((a: BlogMetaNodeType, b: BlogMetaNodeType) =>
			new Date(a.date) > new Date(b.date) ? -1 : 1
		);
};

export const metaQuery = () => {
	return useStaticQuery(graphql`
		query blogMetaContent {
			allContentfulTheNextShiftInsight(filter: { rapOnly: { eq: false } }) {
				nodes {
					node_locale
					url
					title
					heroImage {
						file {
							url
						}
					}
					date
					category
					rapOnly
				}
			}
		}
	`);
};
