import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Link } from "@ryerson/frontend.navigation";

const Container = styled.div`
	display: inline-block;
	height: 45px;
	padding: 13px 26px;
	border-radius: 47px;
	box-sizing: border-box;
	cursor: pointer;
	width: auto;
`;

type ButtonLinkProps = {
	children: any;
	to: string;
	className?: string;
	external?: boolean;
};

const ButtonLink: React.FC<ButtonLinkProps> = ({ children, to, className, external = true }) => {
	const { theme } = useTheme();

	if (external) {
		return (
			<a href={to} target="_blank">
				<Container
					className={className}
					css={css`
						background-color: ${theme.colors.primary.accentBrand};
					`}
				>
					<Typography
						variant="div"
						css={css`
							line-height: 19px;
							font-size: 16px;
						`}
						weight="bold"
						color={theme.colors.primary.secondaryBrand}
					>
						{children}
					</Typography>
				</Container>
			</a>
		);
	} else {
		return (
			<Link to={to}>
				<Container
					className={className}
					css={css`
						background-color: ${theme.colors.primary.accentBrand};
					`}
				>
					<Typography
						variant="div"
						css={css`
							line-height: 19px;
							font-size: 16px;
						`}
						weight="bold"
						color={theme.colors.primary.secondaryBrand}
					>
						{children}
					</Typography>
				</Container>
			</Link>
		);
	}
};

export default ButtonLink;
