import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Container = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: repeat;
`;

type DotsProps = {
	className?: string;
	type?: "light" | "dark";
};

const Dots: React.FC<DotsProps> = ({ type = "light", className }) => {
	return (
		<Container
			className={className}
			css={css`
				background-image: url(/images/${type}-dot.jpg);
			`}
		/>
	);
};

export default Dots;
