import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import Layout from "@components/Layout";
import Container from "@components/Container";
import { renderRichText } from "@components/Contentful";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { MainContent } from "@graphQL/mainContent";
import { useTheme } from "@ryerson/frontend.theme";
import { Video } from "@components/Video";
import Meta, { MetaContent } from "@components/MetaHelmet";

const SEO: MetaContent = {
	title: "About - The Next Shift",
	description:
		"The Next Shift provides market intelligence that helps manufacturers build a globally competitive supply chain.",
	url: "/about",
};

const Dots = styled.div`
	display: block;
	width: 100%;
	padding: 34px 0px 38px 0px;
	background-repeat: repeat;
	box-sizing: border-box;
	background-image: url("/images/light-dot.jpg");
	height: auto;
`;

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	box-sizing: border-box;
	opacity: 0.2;
`;

export default () => {
	const mainContent = MainContent();
	const { theme } = useTheme();
	return (
		<Layout>
			<Meta content={SEO} />
			<Container
				css={css`
					margin-bottom: 2px;
				`}
			>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
					`}
				/>
			</Container>
			<Dots>
				<Container
					css={css`
						background-color: ${theme.colors.primary.white};
						padding-top: 40px;
						padding-bottom: 40px;
						padding-left: 105px;
						box-sizing: border-box;
						padding-right: 105px;
						width: 1000px;
						ul {
							display: block;
							margin-left: auto;
							margin-right: auto;
							max-width: 650px;
							padding-left: 0px;
							list-style: none;
						}
						ul li {
							padding-left: 20px;
							box-sizing: border-box;
							display: block;
							width: 100%;
							position: relative;
						}
						ul li div {
							margin-top: 10px;
							margin-bottom: 10px;
						}
						ul li::before {
							content: "";
							width: 10px;
							display: block;
							height: 10px;
							border-radius: 10px;
							background-color: ${theme.colors.primary.primaryBrand};
							position: absolute;
							left: 0px;
							top: 6px;
						}
					`}
				>
					<Typography
						variant="h1"
						css={css`
							text-align: center;
							margin-bottom: 40px;
						`}
					>
						ABOUT US
					</Typography>
					{renderRichText(
						mainContent.aboutUsRichContent.raw,
						mainContent.aboutUsRichContent.references
					)}
				</Container>
			</Dots>

			<Container
				css={css`
					margin-top: 20px;
					margin-bottom: 20px;
				`}
			>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
					`}
				/>
			</Container>
			<Container
				css={css`
					width: 640px;
					height: 360px;
					@media (max-width: ${theme.breakpoints.lg}) {
						height: 240px;
					}
				`}
			>
				<Video
					videoId={mainContent.aboutUsVideoLink}
					imageUrl={mainContent.aboutUsVideoThumbnailOrImage.file.url}
				/>
			</Container>
			<Container
				css={css`
					margin-top: 20px;
				`}
			>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
					`}
				/>
			</Container>
		</Layout>
	);
};
