import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { IndexedLayers } from "@ryerson/frontend.common";

type CookieBannerType = {
	background: string;
	opacity: number;
	visible: "visible" | "hidden";
};

const CookieBanner = styled.div`
	display: block;
	width: 100%;
	position: fixed;
	bottom: 0;
	height: auto;
	box-sizing: border-box;
	z-index: ${IndexedLayers.modal};
	padding: 24px 0px;
	${(props: CookieBannerType) => {
		const { background, opacity, visible } = props;
		return css`
			background-color: ${background};
			opacity: ${opacity};
			visibility: ${visible};
			transition: ${opacity === 1
				? "opacity 0.5s linear 0.25s, visibility 0.5s linear 0.25s"
				: "opacity 0.5s linear, visibility 0.5s linear"};
		`;
	}}
`;

type CookieBannerContent = {
	text: string;
	linkLabel: string;
	link: string;
	buttonLabel: string;
};

const CookieContent: CookieBannerContent = {
	text: `We use cookies to improve your experience on our website. By continuing
        to use this website, you agree to the use of cookies.To learn more about
        how we use cookies, please see our privacy policy.`,
	linkLabel: "Privacy Policy",
	link: "/documents/Privacy-Policy-Ryerson.pdf",
	buttonLabel: "Accept",
};

export default () => {
	const { theme } = useTheme();
	const [showCookieBanner, setShowCookieBanner] = React.useState<boolean>(false);
	const [renderCookieBanner, setRenderCookieBanner] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (localStorage?.getItem("acceptedCookies") === null) {
			//User has not actively OR passively accepted cookies
			setShowCookieBanner(true);
			setRenderCookieBanner(true);
		}
	}, []);

	const acceptCookies = () => {
		setShowCookieBanner(false);
		localStorage?.setItem("acceptedCookies", "true");
	};

	const handleTransitionEnd = (e: React.TransitionEvent) => {
		e.currentTarget.attributes.getNamedItem("opacity")?.value === "0" &&
			setRenderCookieBanner(false);
	};

	if (!renderCookieBanner) return null;

	return (
		<CookieBanner
			background={theme.colors.primary.gray}
			opacity={showCookieBanner === true ? 1 : 0}
			onTransitionEnd={(e) => {
				handleTransitionEnd(e);
			}}
			visible={showCookieBanner === true ? "visible" : "hidden"}
		>
			<ContentSection
				vPadding="0px"
				type="split"
				split={{
					splitDirection: "vertical",
					splitColors: [{ color: theme.colors.primary.gray, percentage: "100%" }],
				}}
			>
				<Flex direction="row" justifyContent="space-between" alignItems="center">
					<FlexItem>
						<Typography
							variant="div"
							size="md"
							color={theme.colors.primary.white}
							css={css`
								line-height: 20px !important;
							`}
						>
							{CookieContent.text}{" "}
							<Link
								to={CookieContent.link}
								gatsby={false}
								target="_blank"
								color={theme.colors.primary.white}
							>
								{CookieContent.linkLabel}
							</Link>
							.
						</Typography>
					</FlexItem>
					<FlexItem>
						<Button
							size="sm"
							label={CookieContent.buttonLabel}
							onClick={acceptCookies}
							type="secondary"
						/>
					</FlexItem>
				</Flex>
			</ContentSection>
		</CookieBanner>
	);
};
