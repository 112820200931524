import React from "react";
import { Helmet } from "react-helmet";

export type MetaContent = {
	title: string;
	description: string;
	imageUrl?: string;
	url: string;
};

export type MetaProps = {
	content: MetaContent;
	children?: any;
};

const Meta: React.FC<MetaProps> = ({ content, children }) => {
	const title = content.title;
	const description = content.description;
	const imageUrl = content.imageUrl ? content.imageUrl : "/images/the-next-shift.png";
	const url = content.url;
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="title" content={title} />
			<meta property="og:title" content={title} />
			<meta name="twitter:title" content={title} />
			<meta name="description" content={description} />
			<meta name="twitter:description" content={description} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:url" content={url} />
			<meta name="twitter:image" content={imageUrl} />
			<meta name="twitter:card" content={imageUrl} />
			<link rel="canonical" href={url} />
			{children && children}
		</Helmet>
	);
};

export default Meta;
