import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import Layout from "@components/Layout";
import { MainContent } from "@graphQL/mainContent";
import { BlogMetaContent } from "@graphQL/blogMetaContent";
import Container from "@components/Container";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Video } from "@components/Video";
import BlogList from "@components/BlogList";
import Meta, { MetaContent } from "@components/MetaHelmet";

const SEO: MetaContent = {
	title: "The Next Shift",
	description:
		"The Next Shift provides market intelligence that helps manufacturers build a globally competitive supply chain.",
	url: "/",
};

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	box-sizing: border-box;
	opacity: 0.2;
`;

export default () => {
	const mainContent = MainContent();
	const blogMetaContent = BlogMetaContent();
	const { theme } = useTheme();

	return (
		<Layout>
			<Meta content={SEO} />
			<Divider
				css={css`
					border-top: 1px solid ${theme.colors.tertiary.background};
					margin-bottom: 2px;
				`}
			/>
			<Media greaterThanOrEqual="lg">
				<BlogList
					blogMetaContent={blogMetaContent}
					itemsPerPage={3}
					itemsPerRow={3}
					title="WHAT'S NEW"
				/>
			</Media>
			<Media lessThan="lg">
				<BlogList
					blogMetaContent={blogMetaContent}
					itemsPerPage={2}
					itemsPerRow={2}
					title="WHAT'S NEW"
				/>
			</Media>
			<Container>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
						margin-bottom: 60px;
						@media (max-width: ${theme.breakpoints.lg}) {
							margin-bottom: 40px;
						}
					`}
				/>
				<Typography
					variant="div"
					type="primary"
					weight="bold"
					color={theme.colors.primary.secondaryBrand}
					css={css`
						margin-bottom: 40px;
						text-align: center;
						font-size: 32px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 32px;
							margin-bottom: 20px;
						}
					`}
				>
					NEXT UP
				</Typography>
				<Flex
					direction="row"
					justifyContent="space-between"
					css={css`
						gap: 30px;
						@media (max-width: ${theme.breakpoints.lg}) {
							flex-direction: column;
							gap: 20px;
						}
					`}
				>
					<FlexItem
						css={css`
							width: 50%;
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
							}
						`}
					>
						<Video
							videoId={mainContent.homepageLeftVideoId}
							imageUrl={mainContent.homepageLeftVideoThumbnail.file.url}
						/>
						<Typography
							variant="div"
							color={theme.colors.primary.secondaryBrand}
							css={css`
								margin-top: 20px;
								text-align: center;
								font-size: 20px;
								@media (max-width: ${theme.breakpoints.lg}) {
									font-size: 16px;
									margin-top: 10px;
									margin-bottom: 20px;
								}
							`}
							weight="bolder"
						>
							{mainContent.homepageLeftVideoTitle}
						</Typography>
					</FlexItem>
					<FlexItem
						css={css`
							width: 50%;
							@media (max-width: ${theme.breakpoints.lg}) {
								width: 100%;
							}
						`}
					>
						<Video
							videoId={mainContent.homepageRightVideoId}
							imageUrl={mainContent.homepageRightVideoThumbnail.file.url}
						/>
						<Typography
							variant="div"
							color={theme.colors.primary.secondaryBrand}
							css={css`
								margin-top: 20px;
								font-size: 20px;
								text-align: center;
								@media (max-width: ${theme.breakpoints.lg}) {
									font-size: 16px;

									margin-top: 10px;
									margin-bottom: 20px;
								}
							`}
							weight="bolder"
						>
							{mainContent.homepageRightVideoTitle}
						</Typography>
					</FlexItem>
				</Flex>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
						margin-top: 124px;
						@media (max-width: ${theme.breakpoints.lg}) {
							margin-top: 62px;
						}
					`}
				/>
			</Container>
		</Layout>
	);
};
