import React from "react";
import { Media } from "@ryerson/frontend.layout";
import Layout from "@components/Layout";
import { BlogMetaContent } from "@graphQL/blogMetaContent";
import Container from "@components/Container";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import BlogList from "@components/BlogList";
import Meta, { MetaContent } from "@components/MetaHelmet";

const SEO: MetaContent = {
	title: "Insights - The Next Shift",
	description:
		"Tapping into the collective knowledge and expertise of experienced fabrication professionals to tackle the biggest questions facing manufacturing.",
	url: "/insights",
};

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	box-sizing: border-box;
	opacity: 0.2;
`;

export default () => {
	const blogMetaContent = BlogMetaContent();
	const { theme } = useTheme();

	return (
		<Layout>
			<Meta content={SEO} />
			<Divider
				css={css`
					border-top: 1px solid ${theme.colors.tertiary.background};
					margin-bottom: 2px;
				`}
			/>
			<Media greaterThanOrEqual="lg">
				<BlogList
					blogMetaContent={blogMetaContent}
					itemsPerPage={6}
					itemsPerRow={3}
					title="INSIGHTS"
				/>
			</Media>
			<Media lessThan="lg">
				<BlogList
					blogMetaContent={blogMetaContent}
					itemsPerPage={6}
					itemsPerRow={2}
					title="INSIGHTS"
				/>
			</Media>
			<Container>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
						margin-bottom: ${blogMetaContent.length <= 6 ? "0px" : "60px"};
						@media (max-width: ${theme.breakpoints.lg}) {
							margin-bottom: ${blogMetaContent.length <= 6 ? "0px" : "40px"};
						}
					`}
				/>
			</Container>
		</Layout>
	);
};
