import React from "react";
import styled from "@emotion/styled";
import Header from "@components/Header";
import Footer from "@components/Footer";

const Body = styled.div`
	display: block;
	width: 100%;
	height: auto;
`;

type LayoutProps = {
	children?: any;
};

const NextLayout: React.FC<LayoutProps> = ({ children }) => {
	return (
		<>
			<Header />
			<Body>{children}</Body>
			<Footer />
		</>
	);
};

export default NextLayout;
